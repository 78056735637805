import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as _ from "underscore";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";

import ReactSelect from "react-select";
import Loader from "../../../components/Loader";
import { getItemFromLocalStorage } from "../../../helper/helper";
import AdminImages from "./../../../constants/admin/images";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import PublicImages from "../../../constants/public/images";
import Layout from "../../../layouts/admin/Layout";
import {
  getFilterHistory,
  updateFilterHistory,
} from "../../../helper/filterHistory";
import PostPreview from "./PostPreview";

const PostByChallenges = () => {
  const navigate = useNavigate();
  let challengeId = "";
  let isChallengeLive = "";
  const location = useLocation();
  const { state } = location;
  if (
    state?.challengeId === "" ||
    state?.challengeId === undefined ||
    state?.challengeId === null ||
    state?.challengeId === 0
  ) {
    navigate("/Challenges");
  } else {
    challengeId = state?.challengeId;
  }
  if (
    state?.isChallengeLive === "" ||
    state?.isChallengeLive === undefined ||
    state?.isChallengeLive === null
  ) {
    navigate("/Challenges");
  } else {
    isChallengeLive = state?.isChallengeLive;
  }

  const [tableHead, setTableHead] = useState();
  const [pending, setPending] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [search, setSearch] = useState("");
  const [latestPosts, setLatestPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const winnerOptions = [
    { value: "all", label: "All" },
    { value: "winners", label: "Winners" },
    { value: "starmark", label: "Starmark" },
  ];
  // const winnerOptions = isChallengeLive
  // ? [
  //     { value: "all", label: "All" },
  //     { value: "winners", label: "Winners" },
  //     { value: "starmark", label: "Starmark" },
  //   ]
  // : [
  //     {
  //       value: "starmark",
  //       label: "Starmark",
  //     },
  //   ];
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [winnerFilter, setWinnerFilter] = useState("");
  const [modalState, setModalState] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null); // To store the selected post

  const handleShowModal = (post) => {
    setSelectedPost(post); // Set the clicked post data
    setModalState(true); // Open the modal
  };

  const handleCloseModal = () => {
    setModalState(false); // Close the modal
    setSelectedPost(null); // Clear the selected post data
  };
  const getAllPosts = async (
    page = 1,
    limit = 10,
    search = "",
    status = "",
    startDate = "",
    endDate = ""
  ) => {
    try {
      setCurrentPage(page);
      setPerPage(limit);
      setSearch(search);
      setWinnerFilter(status);
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/challenge/get-challenge-posts`,
        token,
        {
          limit: limit,
          page: page,
          search: search,
          getWinners: status ? status?.value : "",
          challengeId: challengeId,
        }
      );
      setLatestPosts(response?.result?.data?.posts);
      setTableData(response?.result?.data?.posts);
      setTotalRows(response?.result?.data?.competitorCount);
      setTableHead([
        {
          name: "Preview",
          right: true,
          grow: 1,
          sortable: false,
          button: true,
          cell: (row, index, column, id) => (
            <>
              <Button
                size="sm"
                variant=""
                onClick={() => handleShowModal(row)} // Pass the post data to handleShowModal
              >
                <Image
                  src={uploadURL + row.featuredImage}
                  fluid
                  height={50}
                  width={50}
                  roundedCircle
                  style={{
                    height: "50px",
                    width: "50px",
                    objectFit: "cover",
                    border:"1px solid #fff"
                  }}
                />
              </Button>
            </>
          ),
        },
        {
          name: "Title",
          sortable: true,
          selector: (row) => row.title,
        },
        {
          name: "Sub Category Name",
          selector: (row) => row.subCategoryId?.name,
        },
        {
          name: "Category Name",
          selector: (row) => row.categoryId.slug,
        },
        {
          name: "Product Type",
          selector: (row) => row.productId.name,
        },
        {
          name: "User",
          selector: (row) => `${row.userId.firstName} ${row.userId.lastName}`,
        },
        {
          name: "Date",
          selector: (row) => moment(row.createdAt).format("D-M-Y"),
        },
        {
          name: "Actions",
          button: true,
          right: true,
          grow: 1,
          sortable: false,
          cell: (row, index, column, id) => (
            <>
              <Button
                className="del_btn ms-1"
                size="sm"
                variant=""
                onClick={() => chooseStarMark(row._id)}
              >
                <img
                  src={AdminImages.StarmarkGold}
                  alt=""
                  height={25}
                  width={25}
                />
              </Button>
              {!isChallengeLive && (
                <Button
                  className="del_btn ms-1"
                  size="sm"
                  variant=""
                  onClick={() => chooseWinner(row._id)}
                >
                  <img
                    src={PublicImages.Trophy}
                    alt=""
                    height={25}
                    width={25}
                  />
                </Button>
              )}
              <Link
                to={`/${row.productId.slug}/${row.categoryId?.slug}/${row.slug}`}
                state={{ isModeration: !row.isApproved }}
                target="_BLANK"
                className="btn btn-sm text-light btn-info view_btn ms-1"
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </>
          ),
        },
      ]);
    } catch (error) {
      console.log("Get Moderations Error", error);
    } finally {
      setPending(false);
    }
  };

  const handlePageChange = (page) => {
    updateFilterHistory(
      {
        page: page,
        limit: perPage,
        search,
        status: winnerFilter,
      },
      "postByChallenges",
      navigate,
      state
    );
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    updateFilterHistory(
      {
        page: currentPage,
        limit: newPerPage,
        search,
        status: winnerFilter,
      },
      "postByChallenges",
      navigate,
      state
    );
  };
  const handleWinnerFilter = (selectedOption) => {
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search,
        status: selectedOption,
      },
      "postByChallenges",
      navigate,
      state
    );
  };

  const handleSearchCategory = (e) => {
    const searchValue = e.target.value;
    updateFilterHistory(
      {
        page: 1,
        limit: 10,
        search: searchValue,
        status: winnerFilter,
      },
      "postByChallenges",
      navigate,
      state
    );
  };
  const chooseWinner = async (postId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/challenge/choose-winner`,
        token,
        {
          postId,
          challengeId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(result?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("winner Post Error", error);
    } finally {
      getAllPosts();
      setPending(false);
    }
  };

  const chooseStarMark = async (postId) => {
    try {
      setPending(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await putRequest(
        `/api/secure/challenge/star-mark`,
        token,
        {
          postId,
          challengeId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        toast.success(result?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("winner Post Error", error);
    } finally {
      getAllPosts();
      setPending(false);
    }
  };

  useEffect(() => {
    getFilterHistory(location, getAllPosts);
  }, [location]);
  const conditionalRowStyles = [
    {
      when: (row) => row.isWinner === true && row.isStarmarked === false,
      style: {
        backgroundColor: "#e5097d",
        color: "white",
      },
    },
    {
      when: (row) => row.isStarmarked === true && row.isWinner === false,
      style: {
        backgroundColor: "#48277f",
        color: "white",
      },
    },
    {
      when: (row) => row.isStarmarked === true && row.isWinner === true,
      style: {
        backgroundColor: "#000",
        color: "white",
      },
    },
  ];

  return (
    <Layout>
      <section id="Posts_list" className="pt-5">
        <Container>
          <Row className="mb-3">
            <Col lg={6} md={6} xs={12}>
              <FormControl
                type="text"
                placeholder="Search Posts"
                className="me-2"
                value={search}
                onChange={handleSearchCategory}
              />
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Form.Group>
                <ReactSelect
                  placeholder="Filter By Winner"
                  onChange={handleWinnerFilter}
                  options={winnerOptions}
                  value={winnerFilter}
                  required
                  isClearable
                />
              </Form.Group>
            </Col>
          </Row>
          {pending ? (
            <Loader loading={pending} />
          ) : (
            <DataTable
              columns={tableHead}
              data={_.sortBy(tableData, "total").reverse()}
              style={{
                borderRadius: "10px",
              }}
              pagination
              paginationServer
              paginationPerPage={perPage}
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              progressPending={pending}
              conditionalRowStyles={conditionalRowStyles}
            />
          )}
        </Container>
      </section>
      {selectedPost && (
        <PostPreview
          post={selectedPost}
          show={modalState}
          handleClose={handleCloseModal}
          onStarMark={chooseWinner}
          onMakeWinner={chooseStarMark}
        />
      )}
    </Layout>
  );
};

export default PostByChallenges;
