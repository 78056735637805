import React, { useEffect, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../components/Loader";
import PublicImages from "../../constants/public/images";
import { getRequest, uploadURL } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { Autoplay } from "swiper/modules";
moment.tz.setDefault("Asia/Kolkata");

// import SwiperCore, Naviga from 'swiper/core';
// SwiperCore.use([Navigation]);
export default function Product() {
  const { product } = useParams();
  const { state } = useLocation();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(true);
  const [challengesLoading, setChallengesLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [challenges, setChallenges] = useState([]);

  const loadCategory = async (masterProductSlug) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(`/api/secure/category/get-category`, token, {
        masterProductSlug,
      });
      if (res.result.status === 200 || res.result.status === 201) {
        setCategories(res.result.data.category);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const loadChallenges = async (masterProductSlug) => {
    try {
      setChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-type`,
        "",
        {
          productSlug: masterProductSlug,
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChallengesLoading(false);
      loadCategory(product);
    }
  };
  useEffect(() => {
    setChallenges([]);
    setCategories([]);
    if (
      !(
        product === "blogs" ||
        product === "short-stories" ||
        product === "videos" ||
        product === "audio-station" ||
        product === "image-corner"
      )
    ) {
      navigator("/404");
    } else {
      loadChallenges(product);
    }
    console.log("state", state);
  }, [product, state]);
  return (
    <Layout>
      {challengesLoading ? (
        <Loader loading={challengesLoading} />
      ) : (
        <>
          <section className="popular_sec">
            <Container>
              <h1>
                <img src={PublicImages.pages} className="img-fluid" alt="" />
                Challenges
              </h1>
              {challenges.length > 0 ? (
                <Swiper
                  navigation={true} // Show navigation arrows
                  spaceBetween={20} // Space between slides
                  slidesPerView={4} // Number of slides to show
                  loop={true} // Enable loop
                  loopAdditionalSlides={1} // Number of additional slides to loop (1 for circular)
                  autoplay={{ delay: 5000, disableOnInteraction: false }} // Enable autoplay
                  modules={[Autoplay]}
                  breakpoints={{
                    // For mobile devices
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    // For desktop and larger screens
                    1024: {
                      slidesPerView: 4,
                    },
                  }}
                >
                  {challenges.map(
                    ({ slug, photo, name, challengeEndDate }, index) => (
                      <SwiperSlide key={index}>
                        <Card className="card_style_2 ">
                          <Link
                            reloadDocument={true}
                            to={`/${product}/challenges/${slug}`}
                            className="text-decoration-none text-dark d-block  w-100"
                            style={{ cursor: "pointer", position: "relative" }}
                          >
                            <LazyLoadImage
                              effect="blur"
                              src={uploadURL + photo}
                              className="img-fluid w-100 challengeImage"
                              wrapperClassName="d-block"
                              alt=""
                            />
                            {/* {challengeEndDate &&
                              moment(new Date()).isBefore(challengeEndDate) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )} */}
                            {challengeEndDate &&
                              moment(challengeEndDate).isSameOrAfter(
                                moment(new Date()).format("YYYY-MM-DD")
                              ) && (
                                <span className="badge badge-primary challenge_live">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#fff"
                                    className={`pe-2 fa-beat Blink`}
                                  />
                                  Live
                                </span>
                              )}

                            <Card.Body className="text-center">
                              <Card.Title>
                                <p>{name}</p>
                              </Card.Title>
                            </Card.Body>
                          </Link>
                        </Card>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              ) : (
                <Row>
                  <Col className="text-center" xs={12}>
                    <p>No Challeges Added Yet!</p>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
        </>
      )}

      {loading ? (
        <Loader loading={loading} />
      ) : (
        <>
          <section className="categories">
            <Container>
              <h1 className="text-center">
                <img src={PublicImages.forum} className="img-fluid" alt="" />
                CATEGORIES
              </h1>
              <Row>
                {categories.length > 0 ? (
                  categories.map(({ slug, featuredImage, name }, index) => (
                    <Col key={index} lg={4} md={4} xs={12}>
                      <Card>
                        <Link
                          reloadDocument={true}
                          to={`/${product}/${slug}`}
                          state={{ status: state?.status }}
                          className="text-decoration-none"
                        >
                          {featuredImage && (
                            <LazyLoadImage
                              src={uploadURL + featuredImage}
                              alt={name}
                              effect="blur"
                              className="card-img"
                              delayTime={5000}
                              wrapperClassName="d-block"
                            />
                          )}
                          <Card.Body className="text-center">
                            <Card.Title>
                              <p>{name}</p>
                            </Card.Title>
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col className="text-center" xs={12}>
                    <p>No Category Added Yet!</p>
                  </Col>
                )}
              </Row>
            </Container>
          </section>
        </>
      )}

      {/* <section className="submit">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-6 col-12 text-center">
              <button className="btn-default">Load More</button>
            </div>
          </div>
        </div>
      </section> */}
    </Layout>
  );
}
