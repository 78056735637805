import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Ratio, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import { getRequest, postRequestForm } from "../../../../helper/api";
import Layout from "../../../../layouts/admin/Layout";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputGroup from "react-bootstrap/InputGroup";
import ImageUploading from "react-images-uploading";
import PublicImages from "../../../../constants/public/images";
import { getItemFromLocalStorage } from "../../../../helper/helper";
import { EditorState } from "draft-js";
import PostEditor from "../../../../components/admin/PostEditor";
import { AsyncPaginate } from "react-select-async-paginate";

const AddAudioPost = ({ state }) => {
  const navigate = useNavigate();
  const { product } = state || {};

  useEffect(() => {
    if (!state || !product) {
      navigate(-1); // Go back if state is empty
    }
  }, [state, product, navigate]);

  const [images, setImages] = useState([]);
  const [featuredFile, setFeaturedFile] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [status, setStatus] = useState();
  const [title, setTitle] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [descriptionHTML, setDescriptionHTML] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [category, setCategory] = useState();
  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/user/get-all`,
        token,
        {
          page: page,
          search: search,
        }
      );

      // Check status code
      if (
        result?.status === 200 ||
        result?.status === 201 ||
        result?.status === 304
      ) {
        const currentPage = result?.data?.currentPage || 1;
        const totalPages = result?.data?.totalPages || 1;
        const users = result?.data?.users || [];

        return {
          options: users,
          hasMore: currentPage < totalPages,
          additional: {
            page: page + 1,
          },
        };
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message || "No User Found!");
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error.message);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const handleUserChange = (selectedOption) => {
    setUser(selectedOption);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "audio/mp3": [".mp3"],
    },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const addPostHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();

      if (title) {
        formData.append("title", title);
      } else {
        toast.error("Please Enter Title for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (description) {
        formData.append("article", descriptionHTML);
      } else {
        toast.error("Article is Required for Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      } else {
        toast.error("Please Upload the Thumbnail Image for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (status) {
        formData.append(
          "status",
          typeof status === "object" ? status.value : status
        );
      } else {
        toast.error("Please select Status for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (user) {
        formData.append("userId", user && user._id);
      } else {
        toast.error("Please select User for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (category) {
        formData.append("categoryId", category && category._id);
      } else {
        toast.error("Please select Category for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (subCategory) {
        formData.append("subCategoryId", subCategory && subCategory._id);
      } else {
        toast.error("Please select Sub-Category for a Post", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      formData.append("productId", product && product._id);
      formData.append("hashtagArray", JSON.stringify(hashtags));
      if (featuredFile && featuredFile?.length > 0) {
        featuredFile.map((file) => {
          formData.append("featuredFile", file);
        });
      } else {
        toast.error("Please select the file for a post.", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      console.log("formData", Object.fromEntries(formData));
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/post/create-post-for-admin",
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Post Created", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/Posts");
      }
    } catch (error) {
      console.log("Create Post APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productType: product?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-categories-from-master`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          category: category?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    setSubCategory();
  };
  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
  };

  return (
    <section id="add_user_form_section" className="py-5 upload-video">
      <Container>
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <Form
            className="form_add_user"
            encType="multipart/form-data"
            method="post"
            onSubmit={addPostHandler}
          >
            <Row className="pt-3">
              <Col lg={4} md={4} xs={12}>
                <Form.Label>
                  Title <span>*</span>
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder={`Title`}
                    className="show "
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <ReactSelect
                    placeholder="Select Status"
                    onChange={statusChange}
                    options={statusOptions}
                    value={status ? status : ""}
                  />
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group className="mb-3">
                  <Form.Label>User</Form.Label>
                  <AsyncPaginate
                    value={user ? user : ""}
                    loadOptions={fetchUsers}
                    getOptionLabel={(e) =>
                      `${e.firstName} ${e.lastName} (${e.email})`
                    }
                    getOptionValue={(e) => e._id}
                    onChange={handleUserChange}
                    placeholder="Select User"
                    isClearable
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No User Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
              </Col>
              {product && (
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <AsyncPaginate
                      value={category ? category : ""}
                      loadOptions={fetchCategories}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handleCategoryChange}
                      placeholder="Select Category"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Category Found"}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Form.Group>
                </Col>
              )}
              {category && (
                <Col lg={6} md={6} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Sub Category</Form.Label>
                    <AsyncPaginate
                      value={subCategory ? subCategory : ""}
                      loadOptions={fetchSubCategories}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handleSubCategoryChange}
                      placeholder="Select Sub Category"
                      isClearable
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Sub Category Found"}
                      additional={{
                        page: 1,
                      }}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col lg={12} className="">
                <Form.Group className="mb-3">
                  <Form.Label>Article</Form.Label>
                  <PostEditor
                    editorState={description}
                    onChangeEditor={(editor, html, hashtags) => {
                      setDescription(editor);
                      setDescriptionHTML(html);
                      setHashtags(hashtags);
                    }}
                  />
                </Form.Group>
              </Col>

              <h4 className="mt-5 p-2">
                Post File <span>*</span>
              </h4>

              {featuredFile.length == 0 && (
                <div className="mt-1 dot">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <div className="d-flex">
                      <div className="p-2">
                        <img
                          src={PublicImages.backup}
                          className="img-fluid"
                          alt=""
                        />
                        <input {...getInputProps()} />
                      </div>
                      <div className="p-2">
                        <h5>Drop a file here or click to upload</h5>
                        <p>.mp3, upto 5MB in size</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-center w-100">
                {featuredFile.map((file, key) => (
                  <div className="d-flex gap-4 w-100" key={key}>
                    <audio className="w-100" controls key={key}>
                      <source
                        src={file.preview}
                        type={file.type} // Hardcoded audio type
                      />
                    </audio>
                    <Button
                      onClick={() => {
                        setFeaturedFile([]);
                      }}
                      className="clearAudioFile"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                ))}
              </div>

              <Col lg={12} className="mb-5">
                <ImageUploading
                  value={images}
                  onChange={onChangeImage}
                  dataURLKey="data_url"
                  maxNumber={1}
                  maxFileSize={5000000}
                  acceptType={["jpeg", "jpg", "gif", "png"]}
                  resolutionType="absolute"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                    errors,
                  }) => (
                    <Row className="align-items-center mt-5">
                      <Col lg={2} md={3} xs={12}>
                        {imageList.length > 0 ? (
                          imageList.map((image, index) => (
                            <button
                              className="border-0 bg-transparent"
                              onClick={() => onImageUpdate(index)}
                            >
                              <img
                                src={image["data_url"]}
                                className="img-fluid"
                                alt=""
                              />
                            </button>
                          ))
                        ) : (
                          <button
                            type="button"
                            className="border-0 bg-transparent "
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            <img
                              src={PublicImages.cam}
                              className="img-fluid"
                              alt=""
                            />
                          </button>
                        )}
                      </Col>
                      <Col lg={10} md={9} xs={12}>
                        <h3>Upload Image Post Thumbnail</h3>

                        <div className="dot">
                          {imageList.length > 0 ? (
                            imageList.map((image, index) => (
                              <button
                                className="border-0 bg-transparent"
                                onClick={() => onImageUpdate(index)}
                              >
                                <div className="d-flex justify-content-start text-start">
                                  <div>
                                    <h5>
                                      Drop an image file here or click to upload
                                    </h5>
                                    <p>
                                      .jpeg or .png up to 5MB in size. 1000x1000
                                      high-resolution recommended.
                                    </p>
                                  </div>
                                </div>
                              </button>
                            ))
                          ) : (
                            <button
                              type="button"
                              className="border-0 bg-transparent "
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <div className="d-flex justify-content-start text-start">
                                <div>
                                  <h5>
                                    Drop an image file here or click to upload
                                  </h5>
                                  <p>
                                    .jpeg or .png up to 5MB in size. 1000x1000
                                    high-resolution recommended.
                                  </p>
                                </div>
                              </div>
                            </button>
                          )}
                        </div>
                      </Col>
                    </Row>
                  )}
                </ImageUploading>
              </Col>

              <div className="d-flex  pt-5">
                <InputGroup className="justify-content-center">
                  <Button type="submit" className="btn-default">
                    Submit
                  </Button>
                </InputGroup>
              </div>
            </Row>
          </Form>
        )}
      </Container>
    </section>
  );
};

export default AddAudioPost;
