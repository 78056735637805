import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Image,
  Ratio,
  Alert,
} from "react-bootstrap";
import Layout from "../../layouts/public/Layout";
import PublicImages from "../../constants/public/images";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
// import { Editor } from "react-draft-wysiwyg";
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from "@draft-js-plugins/mention";

import draftToHtml from "draftjs-to-html";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getItemFromLocalStorage } from "../../helper/helper";
import { getRequest, postRequestForm, putRequest } from "../../helper/api";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import htmlToDraft from "html-to-draftjs";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import CombinedEditor from "../../components/public/Pages/CombinedEditor";
import { AsyncPaginate } from "react-select-async-paginate";
import { has, select } from "underscore";
import RichEditor from "../../components/public/Pages/RichEditor";
import createMentionEntities from "../../components/public/Pages/createMentionEntities";
import MentionHashtagEditor from "../../components/ContentEditor";
import ContentEditor from "../../components/ContentEditor";
import PostEditor from "../../components/admin/PostEditor";
export default function Upload() {
  const { state } = useLocation();
  const { product } = useParams();
  const navigate = useNavigate();
  if (!product) {
    navigate(-1);
  }
  const [title, setTitle] = useState();
  const [images, setImages] = useState([]);
  const [featuredFile, setFeaturedFile] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState();
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState();
  const [html, setHTML] = useState("");
  const [shortStories, setShortStories] = useState(false);
  const [categorySelectDisable, setCategorySelectDisable] = useState(false);
  const [errors, setErrors] = useState({
    maxFileSize: false,
    acceptType: false,
    resolution: false,
    aspectRatio: false,
  });
  const getWordCount = (editor) => {
    const words = converEditorToHtml(editor)?.trim().split(/\s+/);
    return words?.length;
    // const plainText = editor.getCurrentContent().getPlainText("");
    // const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
    // const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
    // const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
    // return wordArray ? wordArray.length : 0;
  };
  function converEditorToHtml(editor) {
    const html = draftToHtml(convertToRaw(editor.getCurrentContent()));
    return html;
  }
  const handleSubCategory = (selectedOption) => {
    setSubCategory(selectedOption);
  };

  // const onChangeImage = async (imageList, addUpdateIndex) => {
  //   // Your existing code...
  //   // Use FileReader to read the image file and obtain its dimensions
  //   const file = imageList[addUpdateIndex].file;
  //   const reader = new FileReader();

  //   reader.onload = function (e) {
  //     const imgElement = document.createElement("img");
  //     imgElement.src = e.target.result;

  //     imgElement.onload = function () {
  //       const width = imgElement.width;
  //       const height = imgElement.height;

  //       // Check if the height is less than or equal to half of the width
  //       if (height <= width / 2) {
  //         // Continue with the rest of your onChangeImage logic...
  //         setImages(imageList);
  //         setFeaturedImage(file);
  //       } else {
  //         // Reject the image if the height is greater than half of the width
  //         // You can also show an error message to the user if needed
  //         toast.error(
  //           `Please Upload the Thumbnail Image with 16:9 aspect ratio for a ${formatString(
  //             product
  //           )}.`,
  //           {
  //             position: "top-center", theme: "colored",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //           }
  //         );
  //         return false;
  //       }
  //     };
  //   };

  //   reader.readAsDataURL(file);
  // };
  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const formatString = (input) => {
    return input
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize:
      product === "videos" ? 5e7 : product === "audio-station" ? 5e6 : 1e6,
    accept:
      product === "videos"
        ? {
            "video/mp4": [".mp4", ".MP4"],
          }
        : {
            "audio/mp3": [".mp3"],
          },
    onDropRejected: () => {
      toast.error(
        product === "videos"
          ? `Video size is greater than 50mb.`
          : `Audio size is greater than 5mb.`,
        {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
    },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      const file = acceptedFiles[0];
      if (file) {
        const isVideo = file.type.startsWith("video/");
        // Check if the uploaded file is a video
        if (isVideo) {
          // Capture video thumbnail at 10 seconds
          const video = document.createElement("video");
          video.src = URL.createObjectURL(file);

          video.addEventListener("loadeddata", () => {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            const context = canvas.getContext("2d");
            video.currentTime = 10; // Set the time to capture thumbnail (10 seconds in this case)

            video.addEventListener("seeked", () => {
              context.drawImage(video, 0, 0, canvas.width, canvas.height);
              const thumbnailDataURL = canvas.toDataURL("image/png");
              const thumbnailFile = dataURLtoFile(
                thumbnailDataURL,
                "thumbnail.png"
              );
              // Now you can use thumbnailFile to set the featuredImage state or display it
              setFeaturedImage(thumbnailFile);
              setImages([{ data_url: thumbnailDataURL, file: thumbnailFile }]);
            });
          });
        }
      }
    },
  });
  const addPostHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append(
        "subCategoryId",
        typeof subCategory === "object" ? subCategory.value : subCategory
      );
      if (challenge) {
        formData.append("challengeId", challenge?._id);
      }
      if (hashtags.length) {
        formData.append("hashtagArray", JSON.stringify(hashtags));
      }
      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      } else {
        toast.error(
          `Please Upload the Thumbnail Image for a ${formatString(product)}.`,
          {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
        return false;
      }
      if (
        formatString(product)?.includes("Audio") ||
        formatString(product)?.includes("Video")
      ) {
        if (featuredFile && featuredFile?.length > 0) {
          featuredFile.map((file) => {
            formData.append("featuredFile", file);
          });
        } else {
          alert(
            `Please Upload the ${formatString(
              product
            )} File for a ${formatString(product)}.`
          );
          return false;
        }
      } else {
        // Strip HTML tags and check if the remaining text is empty or contains only whitespace characters
        const strippedHtml = html.replace(/<[^>]+>/g, "");
        if (!strippedHtml.trim()) {
          toast.error(
            `Please Write the Article for a ${formatString(product)}.`,
            {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            }
          );
          return false;
        }
        formData.append("article", html);
      }
      const token = getItemFromLocalStorage("TOKEN");
      const user = getItemFromLocalStorage("USER");
      const userId = JSON.parse(user)?._id;
      formData.append("userId", userId);
      console.log("formData", Object.fromEntries(formData));
      const { result, error } = await postRequestForm(
        "/api/secure/post/create-post",
        token,
        formData
      );

      if (result?.status === 200 || result?.status === 201) {
        const { post, category } = result?.data;
        toast.success("Post Created", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        if (challenge?._id) {
          const { result, error } = await putRequest(
            "/api/secure/challenge/join-challenge",
            token,
            {
              challengeId: challenge?._id,
              postId: post?._id,
            }
          );
          if (result?.status === 200 || result?.status === 201) {
            if (post.isApproved) {
              navigate(`/${product}/published`);
            } else {
              navigate(
                `/${product}/${category?.parentCategoryId?.slug}/under-moderation/${post.slug}`
              );
            }
          }
        } else {
          if (post.isApproved) {
            navigate(`/${product}/published`);
          } else {
            navigate(
              `/${product}/${category?.parentCategoryId?.slug}/under-moderation/${post.slug}`
            );
          }
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      } else if (error?.response?.status === 413) {
        toast.error(`Video size is greater than 50mb.`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Create Post APi error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (e) => {
    if (e.target.value.split(" ").filter(Boolean).length > 10) {
      toast.error("Title must be 10 words or fewer.", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setTitle(e.target.value);
  };
  const fetchAllSubCategories = async (search, prevOptions, { page }) => {
    setSubCategories((prevState) => []);

    const token = getItemFromLocalStorage("TOKEN");
    const response = await getRequest(
      `/api/secure/category/get-all-sub-categories-of-product`,
      token,
      {
        search,
        status: "active",
        product,
        page,
      }
    );

    console.log("response", response);

    const categories = response?.result?.data?.categories;

    if (!categories) {
      return {
        options: [],
        hasMore: false, // or adjust based on your logic
        additional: {
          page: page + 1,
        },
      };
    }

    let hasMore = true;

    if (
      response?.result?.data?.currentPage === response?.result?.data?.totalPages
    ) {
      hasMore = false;
    } else {
      hasMore = true;
    }

    const mappedOptions = categories.map((option) => ({
      value: option._id,
      label: `${option.name}`,
      wordLimit: option.wordLimit,
    }));
    setSubCategories((prevState) => [...prevState, ...mappedOptions]);
    return {
      options: mappedOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const getLabelByValue = (statusOptions, value) => {
    const option = statusOptions?.find((option) => option.value === value);
    return option?.label;
  };

  const getWordLimitByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.wordLimit;
  };

  const onChangeEditorHandle = (editorState, html, hashtags) => {
    setEditor(editorState);
    setHashtags(hashtags);
    setHTML(html);
    console.log(editorState, html, hashtags);
  };

  const getChallengeBySlug = async (challengeSlug) => {
    try {
      setLoading(true);
      console.log("challengeSlug", challengeSlug);
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-challenge-by-slug`,
        token,
        {
          challengeSlug,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { challenge } = result?.data;
        if (challenge) {
          setChallenge(challenge);
          setCategorySelectDisable(true);
          setSubCategory({
            value: challenge?.subCategoryId?._id,
            label: challenge?.subCategoryId?.name,
            wordLimit: challenge?.subCategoryId?.wordLimit,
          });
        }
      } else if (error?.response?.status === 400) {
        toast.error(`${error?.response?.data?.message}`, {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.log("Get Challenge By Id Error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    // fetchAllSubCategories("", "", { page: 1 });
    setSubCategories([]);
    setImages([]);
    setFeaturedFile([]);
    setTitle(state?.title ? state?.title : "");
    if (state?.category) {
      setSubCategory({
        value: state?.category,
        label: state?.categoryLabel,
      });
    } else {
      setSubCategory("");
    }
    if (state?.article) {
      setShortStories(true);
      const contentBlock = htmlToDraft(state?.article);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditor(editorState);
      }
    } else if (formatString(product)?.includes("Stories")) {
      setShortStories(true);
      setEditor(EditorState.createEmpty());
    } else {
      setShortStories(false);
      setEditor(EditorState.createEmpty());
    }
    if (state?.challengeSlug) {
      getChallengeBySlug(state?.challengeSlug);
    }
    console.log(product, state);
  }, [product, state]);

  // Helper function to convert data URL to a File object
  const dataURLtoFile = (dataURL, fileName) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };

  const [editorOutput, setEditorOutput] = useState("<p>Initial content</p>");

  const handleContentChange = ({ html, hashtags }) => {
    setEditorOutput(html); // Update the HTML state
    console.log("Editor output:", html);
    console.log("Hashtags:", hashtags);
  };
  return (
    <Layout>
      <section className="upload-video">
        <Container>
          <h1 className="text-center">
            {product === "videos" ||
            product === "audio-station" ||
            product === "image-corner"
              ? `Upload Your ${formatString(product)}`
              : `Write Your ${formatString(product)}`}
          </h1>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={addPostHandler}
            >
              <Row className="pt-3">
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <h2>
                    {formatString(product)} Title <span>*</span>
                  </h2>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder={`Title of your ${product}`}
                      className="show "
                      required
                      value={title}
                      onChange={handleTitleChange}
                    />
                  </InputGroup>
                </Col>
                <Col lg={6} md={6} xs={12} className="mb-3">
                  <h2>
                    Category <span>*</span>
                  </h2>
                  <InputGroup>
                    {/* <ReactSelect
                        placeholder="Category *"
                        onChange={handleSubCategory}
                        options={subCategories}
                        value={
                          typeof subCategory === "object"
                            ? subCategory
                            : subCategory
                            ? {
                                value: subCategory,
                                label: getLabelByValue(
                                  subCategories,
                                  subCategory
                                ),
                              }
                            : ""
                        }
                        required
                        isClearable
                        onInputChange={(query) => {
                          getAllSubCategories(product, query);
                        }}
                      /> */}
                    <AsyncPaginate
                      placeholder="Select Category *"
                      loadOptions={fetchAllSubCategories}
                      onChange={handleSubCategory}
                      // options={subCategories}
                      key={product}
                      isDisabled={categorySelectDisable}
                      required
                      isClearable
                      value={
                        typeof subCategory === "object"
                          ? subCategory
                          : subCategory
                          ? {
                              value: subCategory,
                              label: getLabelByValue(
                                subCategories,
                                subCategory
                              ),
                            }
                          : ""
                      }
                      onInputChange={(query) => {
                        // getAllSubCategories(product, query);
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </InputGroup>
                </Col>
                {(formatString(product)?.includes("Blogs") ||
                  formatString(product)?.includes("Image") ||
                  formatString(product)?.includes("Stories")) && (
                  <Col lg={12} className="mb-3">
                    <h2>Article </h2>
                    {/* <RichEditor
                      editorState={editor}
                      onChangeEditor={onChangeEditorHandle}
                      placeholder={`Description of your ${product}. A few sentences is usually recommended.`}
                      shortStories={shortStories}
                      setShortStories={setShortStories}
                    />*/}
                    {/* <EditorFinal
                      html={html}
                      onChange={(html) => {
                        setHTML(html);
                      }}
                      // editorState={editor}
                      // onChangeEditor={onChangeEditorHandle}
                      // placeholder={`Description of your ${product}. A few sentences is usually recommended.`}
                      // shortStories={shortStories}
                      // setShortStories={setShortStories}
                    /> */}
                    <PostEditor
                      editorState={editor}
                      onChangeEditor={onChangeEditorHandle}
                      placeholder={`Description of your ${product}. A few sentences is usually recommended.`}
                      isReady={shortStories}
                    />
                    {/* <ContentEditor
                      editorState={editor}
                      onChangeEditor={setEditor}
                      placeholder={`Description of your ${product}. A few sentences is usually recommended.`}
                      /> */}
                  </Col>
                )}

                {(product === "audio-station" || product === "videos") && (
                  <Col lg={12} className="mb-3">
                    <h2>
                      {formatString(product)} File <span>*</span>
                    </h2>

                    {featuredFile.length == 0 && (
                      <div className="mt-1 dot">
                        <div {...getRootProps({ className: "dropzone" })}>
                          <div className="d-flex">
                            <div className="p-2">
                              {formatString(product)?.includes("Audio") ? (
                                <img
                                  src={PublicImages.backup}
                                  className="img-fluid"
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={PublicImages.slidshow}
                                  className="img-fluid"
                                  alt=""
                                />
                              )}
                              <input {...getInputProps()} />
                            </div>
                            <div className="p-2">
                              <h5>
                                Drop an {formatString(product)} file here or
                                click to upload
                              </h5>
                              {formatString(product)?.includes("Audio") ? (
                                <p>.mp3, upto 5MB in size</p>
                              ) : (
                                <p>.mp4, upto 50MB in size</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="d-flex justify-content-center w-100">
                      {featuredFile.map((file, key) => {
                        if (
                          file.type.startsWith("video/") &&
                          formatString(product)?.includes("Videos")
                        ) {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <Ratio aspectRatio="16x9" key={key}>
                                <video controls>
                                  <source src={file.preview} type={file.type} />
                                </video>
                              </Ratio>
                              <Button
                                onClick={() => {
                                  setFeaturedFile([]);
                                  setImages([]);
                                  setFeaturedImage("");
                                }}
                                type="button"
                                className="clearVideoFile"
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (
                          file.type.startsWith("audio/") &&
                          formatString(product)?.includes("Audio")
                        ) {
                          return (
                            <div className="d-flex gap-4 w-100" key={key}>
                              <audio className="w-100" controls key={key}>
                                <source
                                  src={file.preview}
                                  type={file.type} // Hardcoded audio type
                                />
                              </audio>
                              <Button
                                onClick={() => {
                                  setFeaturedFile([]);
                                }}
                                type="button"
                                className="clearAudioFile"
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Col>
                )}
                {!formatString(product)?.includes("Stories") && (
                  <Col lg={12} className="mb-3">
                    <ImageUploading
                      value={images}
                      onChange={onChangeImage}
                      dataURLKey="data_url"
                      maxNumber={1}
                      maxFileSize={5000000}
                      acceptType={["jpeg", "jpg", "gif", "png"]}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                        errors,
                      }) => (
                        <>
                          <Row className="align-items-center flex-column-reverse flex-md-row">
                            <Col lg={2} md={3} xs={12}>
                              {imageList.length > 0 ? (
                                imageList.map((image, index) => (
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent p-0 w-100"
                                    onClick={() => onImageUpdate(index)}
                                  >
                                    <img
                                      src={image["data_url"]}
                                      className="img-fluid w-100 h-100 object-fit-cover"
                                      alt=""
                                    />
                                  </button>
                                ))
                              ) : (
                                <button
                                  type="button"
                                  className="border-0 bg-transparent p-0  w-100"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <img
                                    src={PublicImages.cam}
                                    className="img-fluid w-100"
                                    alt=""
                                  />
                                </button>
                              )}
                            </Col>
                            <Col lg={10} md={9} xs={12}>
                              <div className="dot">
                                {imageList.length > 0 ? (
                                  imageList.map((image, index) => (
                                    <button
                                      type="button"
                                      className="border-0 bg-transparent"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      <div className="d-flex justify-content-start text-start">
                                        <div>
                                          <h5>
                                            Drop an image file here or click to
                                            upload ({formatString(product)} Thumbnail)
                                          </h5>
                                          <p>
                                            .jpeg or .png up to 5MB in size.
                                            16:9 aspect ratio of Image.
                                          </p>
                                        </div>
                                      </div>
                                    </button>
                                  ))
                                ) : (
                                  <button
                                    type="button"
                                    className="border-0 bg-transparent "
                                    onClick={onImageUpload}
                                    {...dragProps}
                                  >
                                    <div className="d-flex justify-content-start text-start">
                                      <div>
                                        <h5>
                                          Drop an image file here or click to
                                          upload  ({formatString(product)} Thumbnail)
                                        </h5>
                                        <p>
                                          .jpeg or .png up to 5MB in size. 16:9
                                          aspect ratio of Image.
                                        </p>
                                      </div>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>

                          {errors && (
                            <div
                              id="imageUploadingErrors"
                              style={{ width: "fitContent" }}
                            >
                              {errors.maxFileSize &&
                                toast.error("Max file size exceeded", {
                                  position: "top-center",
                                  theme: "colored",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: false,
                                  draggable: true,
                                  progress: undefined,
                                })}
                              {errors.acceptType &&
                                toast.error("Invalid Type", {
                                  position: "top-center",
                                  theme: "colored",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: false,
                                  draggable: true,
                                  progress: undefined,
                                })}
                            </div>
                          )}
                        </>
                      )}
                    </ImageUploading>
                  </Col>
                )}
                {/* <Col lg={12} className="mb-3">
                  <h2>Hashtags </h2>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      // as="textarea" rows={2}
                      placeholder={`Hashtags of your ${product}. Separate each hashtag with a comma and make sure to use the # symbol.`}
                      className="show "
                      value={hashtags}
                      onChange={(e) => setHashtags(e.target.value)}
                    />
                  </InputGroup>
                </Col> */}
                <div className="d-flex  pt-5">
                  <InputGroup className="justify-content-center">
                    {!formatString(product)?.includes("Stories") ? (
                      <Button type="submit" className="btn-default">
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="btn-default"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!title) {
                            toast.error(
                              `Title is Required for ${formatString(product)}`,
                              {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            return false;
                          }
                          // Strip HTML tags and check if the remaining text is empty or contains only whitespace characters
                          const strippedHtml = html.replace(/<[^>]+>/g, "");
                          if (!strippedHtml.trim() && !editor) {
                            toast.error(
                              `Please Write the Article for a ${formatString(
                                product
                              )}.`,
                              {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            return false;
                          } else {
                            const wordCount = getWordCount(editor);
                            console.log("wordCount", wordCount);
                            const categoryWordLimit = subCategory?.wordLimit;
                            console.log("categoryWordLimit", categoryWordLimit);
                            if (categoryWordLimit) {
                              if (wordCount > categoryWordLimit) {
                                toast.error(
                                  `Article word count exceeds category's word limit of ${categoryWordLimit}`,
                                  {
                                    position: "top-center",
                                    theme: "colored",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: true,
                                    progress: undefined,
                                  }
                                );
                                return false;
                              }
                            }
                          }
                          if (
                            !subCategory ||
                            subCategory === null ||
                            subCategory === undefined
                          ) {
                            toast.error(
                              `Category is Required for ${formatString(
                                product
                              )}`,
                              {
                                position: "top-center",
                                theme: "colored",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                            return false;
                          }
                          // console.log("Working!!!!");
                          navigate(`/${product}/upload/generate-card`, {
                            state: {
                              title,
                              challengeSlug: state?.challengeSlug
                                ? state?.challengeSlug
                                : "",
                              article: converEditorToHtml(editor),
                              category:
                                typeof subCategory === "object"
                                  ? subCategory.value
                                  : subCategory,
                              categoryLabel:
                                typeof subCategory === "object"
                                  ? subCategory.label
                                  : subCategory,
                            },
                          });
                        }}
                      >
                        Next
                      </Button>
                    )}
                  </InputGroup>
                </div>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
}
